import {
    Box,
    Button,
    Heading,
    Img,
    Text,
    useStyleConfig,
    useBreakpointValue,
    useDisclosure,
    Image,
    Flex,
    SimpleGrid,
} from '@chakra-ui/react'
import React, { ReactElement, useEffect, useState } from 'react'
import { NextPageWithLayout } from '~pages/_app'
import useTranslation from 'next-translate/useTranslation'
import HeadLinks from '~components/sielbleu/head/links'
import Head from 'next/head'
import Layout from '~components/sielbleu/petits-pas/layout'
import PetitsPasCarousel from '~components/sielbleu/petits-pas/Carousel'
import PetitsPasIcon from '~ui/icons/PetitsPasIcon'
import MoveIcon from '~ui/icons/MoveIcon'
import SupportSielBleuIcon from '~ui/icons/SupportSielBleuIcon'

import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react'

const Page: NextPageWithLayout = () => {
    const { t, lang } = useTranslation('sielbleu')
    const styles = useStyleConfig('Landing', { variant: 'PETITSPAS' })

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [hubspotForm, setHubspotForm] = useState<string | null>(null)

    useEffect(() => {
        if (isOpen && hubspotForm) {
            window.hbspt.forms.create({
                region: 'eu1',
                portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
                formId: hubspotForm,
                target: '#hubspotForm',
            })
        }
    }, [isOpen, hubspotForm])

    const openHubspotForm = (formId: string | undefined, fallback: string | undefined) => {
        if (!formId) {
            return
        }

        if (window.hbspt && window.hbspt.forms) {
            setHubspotForm(formId)
            onOpen()
            return
        }

        if (fallback) {
            // We noticed the native tracking protection of Firefox blocks the
            // Hubspot script. This is also a general fallback in case of any
            // loading issues of the hubspot script.
            window.location.href = fallback
        }
    }

    const imgPath = '/images/sielbleu/'

    const smileImage = useBreakpointValue({ base: imgPath + 'petits-pas/sourire_siel_bleu.png' })
    const fundImage = useBreakpointValue({
        base: imgPath + 'petits-pas/visuel_don_webmobile@2x.jpg',
        md: imgPath + 'petits-pas/visuel_don_web.jpg',
    })

    const onlineImage = useBreakpointValue({
        base: imgPath + 'petits-pas/visuel_siel_bleu_online_webmobile@2x.jpg',
        md: imgPath + 'petits-pas/visuel_siel_bleu_online_web.jpg',
    })

    const courcoImage = useBreakpointValue({
        base: imgPath + 'petits-pas/visuel_cours_co_webmobile@2x.jpg',
        md: imgPath + 'petits-pas/visuel_cours_co_web.jpg',
    })

    const nl2br = (text: string) => {
        const lines = text.split('\n')

        return lines.map((item, key) => {
            return (
                <React.Fragment key={key}>
                    {item}
                    {key !== lines.length - 1 && <br />}
                </React.Fragment>
            )
        })
    }

    const datas = {
        personas: [
            {
                //infos profil Marianne
                text: t('sielbleu.petitspas.section1.profile4.text'),
                title: t('sielbleu.petitspas.section1.profile4.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_personna_marianne_02.jpg',
                    alt: t('sielbleu.petitspas.section1.profile2.title'),
                },
            },
            {
                //infos profil Audrée
                text: t('sielbleu.petitspas.section1.profile2.text'),
                title: t('sielbleu.petitspas.section1.profile2.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_personna_audree_01.jpg',
                    alt: t('sielbleu.petitspas.section1.profile2.title'),
                },
            },
            {
                //infos profil Paulette
                text: t('sielbleu.petitspas.section1.profile1.text'),
                title: t('sielbleu.petitspas.section1.profile1.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_personna_paulette_03.jpg',
                    alt: t('sielbleu.petitspas.section1.profile1.title'),
                },
            },
            {
                //infos profil Romuald
                text: t('sielbleu.petitspas.section1.profile3.text'),
                title: t('sielbleu.petitspas.section1.profile3.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_personna_romuald_04.jpg',
                    alt: t('sielbleu.petitspas.section1.profile2.title'),
                },
            },
        ],
        testimonies: [
            {
                //Anne's testimonie
                text: t('sielbleu.petitspas.section3.testimony1.text'),
                status: t('sielbleu.petitspas.section3.testimony1.status'),
                title: t('sielbleu.petitspas.section3.testimony1.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_temoignage_anne.png',
                    alt: t('sielbleu.petitspas.section3.testimony1.title'),
                },
            },
            {
                //Amelie's testimonie
                text: t('sielbleu.petitspas.section3.testimony2.text'),
                status: t('sielbleu.petitspas.section3.testimony2.status'),
                title: t('sielbleu.petitspas.section3.testimony2.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_temoignage_amelie.png',
                    alt: t('sielbleu.petitspas.section3.testimony2.title'),
                },
            },
            {
                //Ms Dabin's testimonie
                text: t('sielbleu.petitspas.section3.testimony3.text'),
                status: t('sielbleu.petitspas.section3.testimony3.status'),
                title: t('sielbleu.petitspas.section3.testimony3.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_temoignage_arpavie.png',
                    alt: t('sielbleu.petitspas.section3.testimony3.title'),
                },
            },
            {
                //Lucienne's testimonie
                text: t('sielbleu.petitspas.section3.testimony4.text'),
                status: t('sielbleu.petitspas.section3.testimony4.status'),
                title: t('sielbleu.petitspas.section3.testimony4.title'),
                img: {
                    src: '/images/sielbleu/petits-pas/visuel_temoignage_lucienne.png',
                    alt: t('sielbleu.petitspas.section3.testimony4.title'),
                },
            },
        ],
        partners: [
            {
                text: 'Crédit municipal de Paris',
                link: 'https://www.creditmunicipal.fr/',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/01_logo_credit_municipal_paris@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/01_logo_credit_municipal_paris.png',
                    }),
                    alt: 'logo du crédit municipal de Paris',
                },
            },
            {
                text: 'SwissLife',
                link: 'https://www.swisslife.fr/home.html',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/02_logo_swisslife@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/02_logo_swisslife.png',
                    }),
                    alt: 'logo de SwissLife',
                },
            },
            {
                text: 'Ebra',
                link: 'https://www.ebra.fr/',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/03_logo_ebra_groupe@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/03_logo_ebra_groupe.png',
                    }),
                    alt: 'logo du groupe de presse Ebra',
                },
            },
            {
                text: 'Toyota',
                link: 'https://www.toyota.fr/',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/04_logo_toyota@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/04_logo_toyota.png',
                    }),
                    alt: 'logo de Toyota',
                },
            },
            {
                text: 'JC Decaux',
                link: 'https://www.jcdecaux.fr/',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/05_logo_jc_decaux@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/05_logo_jc_decaux.png',
                    }),
                    alt: 'logo de JC Decaux',
                },
            },
            {
                text: 'So Press',
                link: 'https://www.sopress.net/',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/06_logo_so_press@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/06_logo_so_press.png',
                    }),
                    alt: 'logo du groupe So Press',
                },
            },
            {
                text: 'Grande cause',
                link: 'https://www.sports.gouv.fr/grande-cause-nationale-2116',
                img: {
                    src: useBreakpointValue({
                        base: '/images/sielbleu/petits-pas/partners/07_logo_bouge_grande_cause_nationale@2x.png',
                        lg: '/images/sielbleu/petits-pas/partners/07_logo_bouge_grande_cause_nationale.png',
                    }),
                    alt: 'logo de la grande cause nationale, Bouger trente minutes par jour',
                },
            },
        ],
    }

    return (
        <>
            <HeadLinks />
            <Head>
                <title>Siel Bleu Petits pas Grandes victoires</title>
                <meta name="description" content={t('sielbleu.petitspas.description.text')} />
                <meta property="og:title" content={t('sielbleu.petitspas.title')} />
                <meta property="og:site_name" content="Siel Bleu" />
                <meta property="og:url" content={process.env.NEXT_PUBLIC_LANDING_PETITSPAS_URL} />
                <meta property="og:description" content={t('sielbleu.petitspas.description.text')} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content={
                        process.env.NEXT_PUBLIC_LANDING_PETITSPAS_URL +
                        '/images/sielbleu/petits-pas/Visuel_header_01_web.jpg'
                    }
                />
                <script async defer type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>
            </Head>
            <Box __css={styles}>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent paddingY={5} maxWidth={'48rem'}>
                        <ModalCloseButton />
                        <ModalBody id={'hubspotForm'}></ModalBody>
                    </ModalContent>
                </Modal>
                <Box position="relative" className="header-banner">
                    <Box
                        position="absolute"
                        bottom={{ base: '27px', md: '40%' }}
                        className="header-text"
                        maxWidth={{ base: '90%', md: '70%' }}
                    >
                        <Heading className="main-title" maxWidth={{ base: '90%', md: '70%' }}>
                            {t('sielbleu.petitspas.title')}
                        </Heading>
                        <Box my="20px"></Box>
                        <a rel="noopener noreferrer" href="https://online.sielbleu.org/" target="_blank">
                            <Button variant="yellow" mt="15px">
                                {t('sielbleu.petitspas.move_cta')}
                            </Button>
                        </a>
                    </Box>
                </Box>
                <Box className="container">
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.petitspas.section4.title')}</Heading>
                            <Text className="section">{t('sielbleu.petitspas.section4.text')}</Text>
                            <SimpleGrid
                                className="card-container"
                                columns={{ base: 1, lg: 2 }}
                                spacing={{ base: 0, lg: 8 }}
                            >
                                <Box className="card">
                                    <Img borderRadius="20px 20px 0px 0px" src={onlineImage} />
                                    <Heading variant="card" p="10px" mt="10px" mx="15px">
                                        {t('sielbleu.petitspas.section4.block1.title')}
                                    </Heading>
                                    <Text p="10px" mx="15px" mb="10px">
                                        {nl2br(t('sielbleu.petitspas.section4.block1.text'))}
                                    </Text>
                                    <Box className="cta">
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://online.sielbleu.org/"
                                            target="_blank"
                                        >
                                            <Button>{t('sielbleu.petitspas.online_cta')}</Button>
                                        </a>
                                    </Box>
                                </Box>
                                <Box className="card">
                                    <Img borderRadius="20px 20px 0px 0px" src={courcoImage} />
                                    <Heading variant="card" p="10px" mt="10px" mx="15px">
                                        {t('sielbleu.petitspas.section4.block2.title')}
                                    </Heading>
                                    <Text p="10px" mx="15px" mb="10px">
                                        {nl2br(t('sielbleu.petitspas.section4.block2.text'))}
                                    </Text>
                                    <Box className="cta">
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://mescours.sielbleu.org/group-classes"
                                            target="_blank"
                                        >
                                            <Button>{t('sielbleu.petitspas.coursco_cta')}</Button>
                                        </a>
                                    </Box>
                                </Box>
                            </SimpleGrid>
                        </Box>
                    </Box>
                    <Box className="support description">
                        <Box className="description-text">
                            <Flex
                                position="relative"
                                backgroundColor="#0380CF"
                                borderRadius="20px"
                                flexDirection={{ base: 'column', lg: 'row' }}
                                maxHeight={{ lg: '400px' }}
                                maxWidth={{ md: '1000px' }}
                            >
                                <Img
                                    src={fundImage}
                                    alt="devices"
                                    borderRadius={{ base: '20px 20px 0px 0px', lg: '20px 0px 0px 20px' }}
                                    maxWidth={{ base: '100%', lg: '500px' }}
                                    maxHeight={{ base: '280px', lg: '500px' }}
                                />
                                <Flex
                                    color="#FFFFFF"
                                    flexDirection="column"
                                    p="30px"
                                    pb="65px"
                                    justifyContent="center"
                                    maxWidth="900px"
                                >
                                    <Heading variant="whiteTitle" my="0px">
                                        {t('sielbleu.petitspas.support_us.title')}
                                    </Heading>
                                    <Text fontSize="25px" casing="uppercase" mt="0px" mb="5px">
                                        {t('sielbleu.petitspas.support_us.subtitle')}*
                                    </Text>
                                    <Text my={{ base: '5px', md: '10px' }}>
                                        {nl2br(t('sielbleu.petitspas.support_us.text'))}
                                    </Text>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://www.sielbleu.org/soutenir"
                                        target="_blank"
                                    >
                                        <Button
                                            my={{ base: '5px', md: '10px' }}
                                            variant="yellow"
                                            maxWidth={{ base: '200px', md: '270px' }}
                                        >
                                            {t('sielbleu.petitspas.make_donation')}
                                        </Button>
                                    </a>
                                    <Text my={{ base: '5px', md: '10px' }} fontSize="xs" maxWidth="90%">
                                        *{t('sielbleu.petitspas.support_us.footnote')}
                                    </Text>
                                    <Img
                                        src={smileImage}
                                        maxWidth={{ base: '157px', xl: '259px' }}
                                        position="absolute"
                                        bottom="10px"
                                        right="10px"
                                        padding="10px"
                                    />
                                </Flex>
                            </Flex>
                        </Box>
                    </Box>
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.petitspas.section3.title')}</Heading>
                            <Text className="section">{t('sielbleu.petitspas.section3.text')}</Text>
                            <PetitsPasCarousel
                                datas={datas.testimonies}
                                renderSlide={(data, index) => (
                                    <Flex
                                        direction={{ base: 'column', lg: 'row' }}
                                        alignItems={{ base: 'center' }}
                                        key={index}
                                    >
                                        <Box className="carousel-pic">
                                            {data.img && (
                                                <Image
                                                    src={data.img.src}
                                                    alt={data.img.alt}
                                                    maxWidth={{ base: '150px !important' }}
                                                />
                                            )}
                                            <Text className="carousel-title">{data.title}</Text>
                                            <Text className="carousel-subtitle">{data.status}</Text>
                                        </Box>
                                        <Box className="carousel-content carousel-quote">
                                            <Text className="carousel-text">{nl2br(data.text)}</Text>
                                        </Box>
                                    </Flex>
                                )}
                            />
                            <Box className="cta">
                                <Button>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://143833571.hs-sites-eu1.com/fr/temoignages-petits-pas"
                                        target="_blank"
                                    >
                                        {t('sielbleu.petitspas.section3.cta')}
                                    </a>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{nl2br(t('sielbleu.petitspas.section1.title'))}</Heading>
                            <Text className="section">{nl2br(t('sielbleu.petitspas.section1.text'))}</Text>
                            <PetitsPasCarousel
                                datas={datas.personas}
                                renderSlide={(data, index) => (
                                    <Flex
                                        direction={{ base: 'column', lg: 'row' }}
                                        alignItems={{ base: 'center' }}
                                        key={index}
                                        style={{ backgroundColor: '#FFFFFF', borderRadius: '20px', padding: '20px' }}
                                    >
                                        <Box className="carousel-pic">
                                            {data.img && <Image src={data.img.src} alt={data.img.alt} />}
                                        </Box>
                                        <Box className="carousel-content">
                                            <Text className="carousel-title">{data.title}</Text>
                                            <Text className="carousel-text">{nl2br(data.text)}</Text>
                                        </Box>
                                    </Flex>
                                )}
                            />
                            <Box className="cta">
                                <Button
                                    onClick={() =>
                                        openHubspotForm(
                                            process.env.NEXT_PUBLIC_HUBSPOT_FORM_PETITSPAS_ID,
                                            process.env.NEXT_PUBLIC_HUBSPOT_FORM_PETITSPAS_URL
                                        )
                                    }
                                >
                                    {t('sielbleu.petitspas.share_cta')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.petitspas.section5.title')}</Heading>
                            <Text className="section">{nl2br(t('sielbleu.petitspas.section5.text'))}</Text>
                            <SimpleGrid
                                className="card-container"
                                columns={{ base: 1, lg: 3 }}
                                spacing={{ base: 0, lg: 8 }}
                            >
                                <Box className="card-blue">
                                    <PetitsPasIcon boxSize={105} />
                                    <Heading variant="card">{t('sielbleu.petitspas.section5.block1.title')}</Heading>
                                    <Text className="section">
                                        {nl2br(t('sielbleu.petitspas.section5.block1.text'))}
                                    </Text>
                                </Box>
                                <Box className="card-blue">
                                    <MoveIcon boxSize={105} />
                                    <Heading variant="card">{t('sielbleu.petitspas.section5.block2.title')}</Heading>
                                    <Text className="section">
                                        {nl2br(t('sielbleu.petitspas.section5.block2.text'))}
                                    </Text>
                                </Box>
                                <Box className="card-blue">
                                    <SupportSielBleuIcon boxSize={105} />
                                    <Heading variant="card">{t('sielbleu.petitspas.section5.block3.title')}</Heading>
                                    <Text className="section">
                                        {nl2br(t('sielbleu.petitspas.section5.block3.text'))}
                                    </Text>
                                </Box>
                            </SimpleGrid>
                        </Box>
                    </Box>
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.petitspas.section7.title')}</Heading>
                            <PetitsPasCarousel
                                datas={datas.partners}
                                centerSlide={50}
                                renderSlide={(data, index) => (
                                    <Flex
                                        direction={{ base: 'column', lg: 'row' }}
                                        alignItems={{ base: 'center' }}
                                        key={index}
                                    >
                                        <Box
                                            width={{
                                                base: '100% !important',
                                                lg: '100% !important',
                                            }}
                                        >
                                            {data.img && <Image src={data.img.src} alt={data.img.alt} />}
                                        </Box>
                                    </Flex>
                                )}
                            />
                        </Box>
                    </Box>
                    <Box className="description" backgroundColor="brand.heavy" justifyContent="center">
                        <Flex
                            px={{ base: '15px' }}
                            flexDirection="column"
                            alignItems="center"
                            width="100%"
                            textAlign="center"
                        >
                            <Heading color="white">{t('sielbleu.petitspas.section6.title')}</Heading>
                            <Text color="white" my="8px" lineHeight="2">
                                {t('sielbleu.petitspas.section6.text')}
                            </Text>
                            <Box className="input-container">
                                <Button
                                    onClick={() =>
                                        openHubspotForm(
                                            process.env.NEXT_PUBLIC_HUBSPOT_FORM_SIELBLEU_ID,
                                            process.env.NEXT_PUBLIC_HUBSPOT_FORM_SIELBLEU_URL
                                        )
                                    }
                                    className="button"
                                    variant="yellow"
                                    borderRadius="10px"
                                    px={10}
                                >
                                    {t('sielbleu.petitspas.section6.cta')}
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
const PageLanding = Page

PageLanding.getLayout = function getLayout(page: ReactElement) {
    return <Layout>{page}</Layout>
}

export default PageLanding
